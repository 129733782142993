import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";

import {
  FooterSection,
  Title,
  NavLink,
  LogoContainer,
  Para,
  Large,
  Empty,
  Language,
} from "./styles";

// interface SocialLinkProps {
//   href: string;
//   src: string;
// }

const Footer = ({ t }: any) => {
  // const handleChange = (language: string) => {
  //   i18n.changeLanguage(language);
  // };

  // const SocialLink = ({ href, src }: SocialLinkProps) => {
  //   return (
  //     <a
  //       href={href}
  //       target="_blank"
  //       rel="noopener noreferrer"
  //       key={src}
  //       aria-label={src}
  //     >
  //       <SvgIcon src={src} width="35px" height="35px" />
  //     </a>
  //   );
  // };

  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="space-between">
            <Col lg={8} md={8} sm={8} xs={8}>
              <Language>{t("Contact")}</Language>
              <Large to="/contact">{t("Tell us everything")}</Large>
              <Para>
                {t(`Do you have any question? Feel free to reach out.`)}
              </Para>
            </Col>
            {/* <Col lg={8} md={8} sm={8} xs={12}>

              <Language>{t("Address")}</Language>
              <Para>Panamá</Para>
              <Para>Ciudad de Panamá</Para>
              <Para>6775-9726</Para>
            </Col> */}
            <Col lg={8} md={8} sm={8} xs={12}>
              <Title>{t("Company")}</Title>
              <Large left="true" to="/about">
                {t("About")}
              </Large>
              <Large left="true" to="/mission">
                {t("Mission")}
              </Large>
            </Col>
            <Col>
            <NavLink to="/">
              <LogoContainer>
                <SvgIcon
                  src="logo-itf3.svg"
                  aria-label="homepage"
                  width="101px"
                  height="101px"
                />
              </LogoContainer>
            </NavLink>
            </Col>
          </Row>
          <Empty />
          <Row justify="space-between">
            {/* <Col lg={8} md={8} sm={8} xs={12}>
              <Label htmlFor="select-lang">{t("Language")}</Label>
              <LanguageSwitchContainer>
                <LanguageSwitch onClick={() => handleChange("en")}>
                  <SvgIcon
                    src="united-states.svg"
                    aria-label="homepage"
                    width="30px"
                    height="30px"
                  />
                </LanguageSwitch>
                <LanguageSwitch onClick={() => handleChange("es")}>
                  <SvgIcon
                    src="spain.svg"
                    aria-label="homepage"
                    width="30px"
                    height="30px"
                  />
                </LanguageSwitch>
              </LanguageSwitchContainer>
            </Col> */}
          </Row>
        </Container>
      </FooterSection>
      {/* <Extra>
        <Container border={true}>
          <Row
            justify="space-between"
            align="middle"
            style={{ paddingTop: "1rem" }}
          >

            <FooterContainer>
              <SocialLink
                href=""
                src="github.svg"
              />
              <SocialLink
                href=""
                src="instagram.svg"
              />
              <SocialLink
                href=""
                src="linkedin.svg"
              />
              <SocialLink
                href=""
                src="discord.svg"
              />
            </FooterContainer>
          </Row>
        </Container>
      </Extra> */}
    </>
  );
};

export default withTranslation()(Footer);
